/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-indent */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { UserGroupIcon, ExclamationCircleIcon, AdjustmentsVerticalIcon, CalendarIcon, UserCircleIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import InputBox from '../components/Base/Elements/Input/InputBox';
import InnerNavButton from '../components/Base/Layout/NavigationElements/InnerNavButton';
import InnerNavButtonSelected from '../components/Base/Layout/NavigationElements/InnerNavButtonSelected';
import DuplicateUserAlert from '../components/Base/Elements/Alerts/DuplicateUserAlert';
import AccountUserLimitAlert from '../components/Base/Elements/Alerts/AccountUserLimitAlert';
import PasswordNotComplexAlert from '../components/Base/Elements/Alerts/PasswordNotComplexAlert';
import EmailFormatAlert from '../components/Base/Elements/Alerts/EmailFormatAlert';
import UserPermissionsModal from '../components/Accounts/UserPermissionsModal';
import PricingBox from '../components/Product/PricingBox';
import ScreenShot from '../../images/screenshot.png';
import Environments from '../components/Environments/Environments';
import logo from '../../images/retrohook.png';
import useWorkspace from '../hooks/useWorkspace';

const Workspace = (props: any) => {
  const {
    subUx,
    setSubUx,
    error,
    setError,
    selectedProduct,
    togglePermissions,
    selectedUser,
    invite,
    billingUX,
    loadingType,
    setLoadingType,
    workspace,
    quantityPurchased,
    setQuantityPurchased,
    selectProduct,
    getCustomerPortal,
    handleNewUserFirstNameChange,
    handleNewUserLastNameChange,
    handleNewUserEmailChange,
    handleMessageIDChange,
    handleOrganizationValueChange,
    openPermissions,
    addUserToWorkspace,
    removeUserFromWorkspace,
    updatePermissions,
    savePermissions,
    saveWorkspace,
    loading,
    awsProductURL,
    removeInviteFromWorkspace,
  } = useWorkspace(props);

  const { selectedWorkspace, environments, getAccount, account, user, setAccount, setSelectedWorkspace, setupEnvironmentsHandler, notify, environmentChanged, setEnvironmentChanged, setEnvironments } = props;

  const members = workspace?.accounts?.filter((account: any) => account.PK != workspace.PK);

  if (loading) {
    return (
      <div className="items-center animate-pulse py-48 sm:py-24 md:py-32 lg:py-48">
        <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
        <h2 className="mt-0 text-center text-2xl font-bold text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>
          Retrohook
          <span className="inline align-middle text-xs tracking-tight text-cyan-300 pl-1" />
        </h2>
      </div>
    );
  }
  return (
    <div>
      <div className="flex h-full">
        <div className="dark:bg-gray-800 bg-cyan-400 flex-1 min-w-0 flex items-center justify-between px-4 h-10">
          <div className="w-full mx-auto py-4 px-6 flex grow">
            <h2 className="text-md text-gray-100 pr-2">
              {account?.email}
            </h2>
          </div>
          {subUx == 'Settings' ? (
            <InnerNavButtonSelected icon={AdjustmentsVerticalIcon} title="General" clickHandler={() => setSubUx('Settings')} />
        ) : (
            <InnerNavButton icon={AdjustmentsVerticalIcon} title="General" clickHandler={() => setSubUx('Settings')} />
        )}
        {subUx == 'Team Settings' ? (
                <InnerNavButtonSelected icon={UserGroupIcon} title="Team" clickHandler={() => setSubUx('Team Settings')} />
        ) : (
            <InnerNavButton icon={UserGroupIcon} title="Team" clickHandler={() => setSubUx('Team Settings')} />
        )}
          {subUx == 'Environments' ? (
            <InnerNavButtonSelected icon={CalendarIcon} title="Environments" clickHandler={() => setSubUx('Environments')} />
        ) : (
            <InnerNavButton icon={CalendarIcon} title="Environments" clickHandler={() => setSubUx('Environments')} />
        )}
        {/* { subUx == 'Subscription' ? (
          <InnerNavButtonSelected icon={CalendarIcon} title="Subscription" clickHandler={() => setSubUx('Subscription')} />
        ) : (
            <InnerNavButton icon={CalendarIcon} title="Subscription" clickHandler={() => setSubUx('Subscription')} />
        )} */}
      </div>
    </div>
    {error == 'UsernameExistsException' && <DuplicateUserAlert closeAlert={() => setError(null)} />}
    {error == 'PasswordNotComplexException' && <PasswordNotComplexAlert closeAlert={() => setError(null)} />}
    {error == 'AccountUserLimitAlert' && <AccountUserLimitAlert closeAlert={() => setError(null)} />}
    {error == 'EmailFormatException' && <EmailFormatAlert closeAlert={() => setError(null)} />}
    <dl>
      <div className="dark:bg-gray-800 border-2 min-h-full mt-4 rounded border-cyan-300 mx-4 overflow-hidden">
        <div>
          {subUx == 'Settings' && (
          <div className="mb-6 sm:flex sm:flex-col sm:align-center">
            <div className="flex w-full text-cyan-100 bg-cyan-400">
              <h2 className="text-lg w-full px-5 py-2 text-cyan-100">General Settings</h2>
              <div className="px-4">
                <button type="button" onClick={() => saveWorkspace(workspace)} 
                  className="inline whitespace-nowrap w-full items-center px-2 py-1 m-1 text-xs text-cyan-100 hover:text-white bg-purple-400 hover:bg-purple-500 border-purple-500 border rounded focus:outline-none">
                  Save
                </button>
              </div>
            </div>
            <div className="gap-2 px-4 pt-8">
              <div className="text-gray-200">
                <InputBox
                  name="Team Name"
                  onChange={handleOrganizationValueChange}
                  placeholder={workspace?.name}
                  defaultValue={workspace?.name}
                />
              </div>
            </div>
            <div className="mx-6" />
            <p className="mx-auto my-8 w-fit text-center text-sm text-gray-600 border-4 px-2 py-2 border-red-300 rounded">
              <ExclamationCircleIcon className="h-6 w-6 inline text-pink-500 rounded" />
              <span className="pl-3 text-pink-500">
                You must include the Workflow Message ID Value below in the stated Workflow Message ID Location of your HL7 Messages for security.
              </span>
            </p>
            <div className="grid grid-cols-12 gap-2 px-4 pb-6">
              <div className="col-span-4">
                <InputBox
                  name="Security Segment"
                  onChange={handleMessageIDChange}
                  placeholder={workspace?.hash?.location}
                  defaultValue={workspace?.hash?.location}
                />
                </div>
                <div className="col-span-8">
                  <InputBox
                    name="Security Token"
                    readOnly
                    disabled
                    placeholder={workspace?.hash?.value}
                    value={workspace?.hash?.value}
                  />
                </div>
              </div>
            </div>
          )}
          {subUx == 'Team Settings' && (
            <div className="sm:flex sm:flex-col sm:align-center">
              <h2 className="w-full text-lg px-2 py-2 text-cyan-100 bg-cyan-400">Members</h2>
                <div className="bg-gray-300 dark:bg-gray-700 min-h-36 overflow-y-auto">
                  <UserPermissionsModal
                    open={togglePermissions}
                    close={savePermissions}
                    updatePermissions={updatePermissions}
                    selectedUser={selectedUser}
                  />
                  <div className="grid grid-cols-4 gap-2 mx-2 py-4">
                  { !members || members.length < 1 && (
                    <div className="flex text-gray-400 col-span-4">
                      <div className="text-2xl w-full items-center text-center align-middle h-full p-6">
                        <UserCircleIcon className="h-9 w-9 rounded mr-1 inline align-middle text-gray-400" />
                        No Team Members Yet
                      </div>
                    </div>
                  )}
                  {members && members.map((user: any, index: number) => (
                    <div
                      key={user.email + index}
                      onClick={() => openPermissions(user)}
                      className="flex text-xs rounded items-center justify-between p-2 border-2 border-purple-400 cursor-pointer hover:bg-purple-500 hover:text-white text-gray-600 dark:text-gray-200"
                    >
                      <div className="text-xs">
                        <img className="w-6 h-6 rounded inline mr-2" src={user?.picture} />{user?.email?.substring(0, 28) || ''}
                      </div>
                      <button
                        className="text-2xs px-3 py-1 bg-gray-400  dark:bg-gray-500 dark:hover:bg-cyan-400 hover:bg-pink-500 text-white rounded-sm focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the parent onClick
                          removeUserFromWorkspace(user);
                          setLoadingType('remove');
                        }}
                      >
                        X
                      </button>
                    </div>
                  ))}
                    
                </div>
              </div>
              <div className="border-cyan-400 border-t-2">
                <h2 className="w-full text-lg px-2 py-2 text-cyan-100 bg-cyan-400">Invitations</h2>
                  <div className="bg-gray-300 dark:bg-gray-700 min-h-36 overflow-y-auto">
                    <div className="grid grid-cols-4 gap-2 mx-2 py-4">
                      { !workspace?.invites || workspace?.invites < 1 && (
                        <div className="flex text-gray-400 col-span-4">
                          <div className="text-2xl w-full items-center text-center align-middle h-full p-6">
                            <EnvelopeIcon className="h-9 w-9 rounded mr-1 inline align-middle text-gray-400" />
                            Send Your First Invite
                          </div>
                        </div>
                      )}
                      {workspace?.invites && workspace?.invites?.map((invite: any, index: number) => (
                        <div
                          key={invite.email + index}
                          className="flex text-xs rounded items-center justify-between p-2 border-2 border-pink-400 cursor-pointer hover:bg-pink-500 hover:text-white text-gray-600 dark:text-gray-200"
                        >
                          <div className="inline">
                            <UserCircleIcon
                              className="h-6 w-6 rounded mr-1 inline text-gray-600 dark:text-gray-200"
                            />
                            <div className="text-xs inline">
                              {invite.email.substring(0, 36) || ''}
                            </div>
                          </div>
                          <button
                        className="text-2xs px-3 py-1 bg-gray-400  dark:bg-gray-500 dark:hover:bg-cyan-400 hover:bg-pink-500 text-white rounded-sm focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the parent onClick
                          removeInviteFromWorkspace(invite);
                        }}
                      >
                        X
                      </button>
                        </div>
                      ))}
                    </div>
                  </div>
                <table className="w-full mt-4 px-4">
                  <tbody>
                    <tr>
                      <td className="px-2 py-4 whitespace-nowrap text-md text-gray-200">
                        <InputBox name="First Name" onChange={handleNewUserFirstNameChange} defaultValue={invite.firstName} />
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap text-md text-gray-200">
                        <InputBox name="Last Name" onChange={handleNewUserLastNameChange} defaultValue={invite.lastName} />
                          </td>
                        <td className="px-2 py-4 whitespace-nowrap text-md text-gray-200">
                        <InputBox name="Email" onChange={handleNewUserEmailChange} defaultValue={invite.email} />
                      </td>
                      {!loading && (
                        <td className="px-2 py-3 whitespace-nowrap text-md text-gray-200">
                          <button
                            className="w-full border-2 border-purple-400 bg-purple-500 hover:bg-purple-400 py-2 px-1 text-white rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={() => {
                              addUserToWorkspace(user.sub);
                              setLoadingType('invite');
                            }}
                          >
                            Send Invite
                          </button>
                        </td>
                      )}
                        {loading && loadingType == 'invite' && (
                          <td className="px-2 py-3 whitespace-nowrap text-md text-gray-200">
                            <button
                            className="w-full bg-gray-500 hover:bg-gray-400 py-2 px-1 text-white rounded focus:outline-none focus:shadow-outline animate-bounce"
                            type="button"
                          >
                              Sending...
                          </button>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {subUx == 'Environments' && (
            <Environments
              user={user}
              account={account}
              setAccount={setAccount}
              environments={environments} 
              selectedWorkspace={selectedWorkspace}
              setSelectedWorkspace={setSelectedWorkspace}
              setupEnvironmentsHandler={setupEnvironmentsHandler}
              notify={notify}
              setEnvironments={setEnvironments}
              environmentChanged={environmentChanged}
              setEnvironmentChanged={setEnvironmentChanged}
              getAccount={getAccount}
            />
          )}
          {subUx == 'Subscription' && (
              <div>
                <PricingBox
                account={account}
                selectProduct={selectProduct}
                getAccount={getAccount}
                awsProductURL={awsProductURL}
                quantityPurchased={quantityPurchased}
                setQuantityPurchased={setQuantityPurchased}
                selectedProduct={selectedProduct}
                getCustomerPortal={getCustomerPortal}
                selectedWorkspace={selectedWorkspace}
                ux={billingUX}
              />
            </div>
          )}
          {subUx == 'Release Notes' && (
              <div className="relative mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="px-4 sm:px-6 lg:px-8">
                      <div className="relative mx-auto max-w-[37.5rem] pt-20 text-center pb-20">
                    <h1 className="text-4xl font-extrabold tracking-tight text-gray-300 sm:text-5xl">Changelog</h1>
                  <p className="mt-4 text-base leading-7 text-cyan-400">
                          Stay up to date with all of the latest additions and improvements we've made to Retrohook. We'll be updating this page regularly with all of the latest changes and will provide a way to subscribe soon.
                  </p>
                </div>
                  </div>
              <section id="2022-12-15" aria-labelledby="2022-12-15-heading" className="md:flex">
                      <h2 id="2022-12-15-heading" className="pl-7 text-md leading-6 text-slate-500 md:w-1/4 md:pl-0 md:pr-6 md:text-right">
                  <a href="#2023-06-01" className="text-cyan-400">
                              June 1st, 2023
                  </a>
                </h2>
                <div className="relative py-4 mx-4">
                  <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true" />
                      <div className="relative flex space-x-2">
                    <div className="min-w-0 flex-1 flex justify-between space-x-1" />
                      <div className="max-w-none prose-h3:mb-4 pl-12 prose-h3:text-base prose-h3:leading-6 prose-sm prose prose-slate prose-a:font-semibold prose-a:text-pink-500 hover:prose-a:text-purple-600">
                        <h2 className="text-red-400 text-2xl">Beta Release 🎉</h2>
                        <p className="py-4 text-gray-200">
                                Today we're happy to announce the launch of our baseline HL7v2 tool. We've been working hard to build a platform that will help you work with HL7 messages quickly and easily. We're excited to share this with you and we hope you enjoy it!
                                Check out our documentation or just start building with our workflow builder.
                      </p>
                        <a href="/workflows">
                          <img className="rounded-xl ring-1 ring-offset-[-1px] ring-slate-700/10" src={ScreenShot} alt="Workflow Builder" />
                      </a>
                    </div>
                  </div>
                  </div>
                  </section>
            </div>
          )}
        </div>
      </div>
    </dl>
  </div>
  );
};

export default Workspace;
