import React from 'react';
import { EnvironmentProvider } from '../../context/EnvironmentContext';
import EnvironmentContent from './EnvironmentContent';

export default function Environments(props: any) {
  const {
    user,
    account,
    selectedWorkspace,
    notify,
    environmentChanged,
    setEnvironmentChanged,
    getAccount,
    setAccount,
    environments,
    setEnvironments,
    setSelectedWorkspace,
  } = props;

  const [showCreateForm, setShowCreateForm] = React.useState(false);

  return (
    <EnvironmentProvider
      user={user}
      account={account || user.account}
      selectedWorkspace={selectedWorkspace}
      notify={notify}
      environmentChanged={environmentChanged}
      setEnvironments={setEnvironments}
      setEnvironmentChanged={setEnvironmentChanged}
      setSelectedWorkspace={setSelectedWorkspace}
      getAccount={getAccount}
      setAccount={setAccount}
      environments={environments}
    >
      <EnvironmentContent setShowCreateForm={setShowCreateForm} showCreateForm={showCreateForm} />
    </EnvironmentProvider>
  );
}
