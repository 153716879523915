/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { TrashIcon, RocketLaunchIcon, CheckIcon, ServerStackIcon } from '@heroicons/react/24/outline';
import { useEnvironment } from '../../context/EnvironmentContext';
import InputBox from '../Base/Elements/Input/InputBox';
import ConfirmModal from '../Base/Elements/Confirmations/ConfirmModal';
import logo from '../../../images/retrohook.png';

const stacks = ['storage', 'network', 'compute'];

const stackStatusMap = {
  CREATE_IN_PROGRESS: <span className="text-cyan-400 text-xs overflow-hidden">Creating...</span>,
  CREATE_FAILED: <span className="text-red-400 text-xs overflow-hidden">Failed</span>,
  CREATE_COMPLETE: <span className="text-green-400 text-xs overflow-hidden">Available</span>,
  ROLLBACK_IN_PROGRESS: <span className="text-cyan-400 text-xs overflow-hidden">Rolling back...</span>,
  ROLLBACK_FAILED: <span className="text-red-400 text-xs overflow-hidden">Rollback failed</span>,
  ROLLBACK_COMPLETE: <span className="text-green-400 text-xs overflow-hidden">Rolled back</span>,
  DELETE_IN_PROGRESS: <span className="text-cyan-400 text-xs overflow-hidden">Deleting...</span>,
  DELETE_FAILED: <span className="text-red-400 text-xs overflow-hidden">Delete failed</span>,
  DELETE_COMPLETE: <span className="text-green-400 text-xs overflow-hidden">Not Found</span>,
  UPDATE_IN_PROGRESS: <span className="text-cyan-400 text-xs overflow-hidden">Updating...</span>,
  UPDATE_COMPLETE_CLEANUP_IN_PROGRESS: <span className="text-cyan-400 text-xs overflow-hidden">Updating...</span>,
  UPDATE_COMPLETE: <span className="text-green-400 text-xs overflow-hidden">Updated</span>,
  UPDATE_ROLLBACK_IN_PROGRESS: <span className="text-cyan-400 text-xs overflow-hidden">Rolling back...</span>,
  UPDATE_ROLLBACK_FAILED: <span className="text-red-400 text-xs overflow-hidden">Rollback failed</span>,
  UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS: <span className="text-cyan-400 text-xs overflow-hidden">Rolling back...</span>,
  UPDATE_ROLLBACK_COMPLETE: <span className="text-green-400 text-xs overflow-hidden">Rolled back</span>,
  REVIEW_IN_PROGRESS: <span className="text-cyan-400 text-xs overflow-hidden">Reviewing...</span>,
};

export default function EnvironmentList() {
  const { environments, loading, deleteEnvironment, deployStack, deleteStack, handleEnvironmentNameChange, saveEnvironmentName, notify } = useEnvironment();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEnvironment, setCurrentEnvironment] = useState<any>(null);
  const [currentStack, setCurrentStack] = useState<string>('');
  const [isEnvModalOpen, setIsEnvModalOpen] = useState(false);

  const openModal = (environment: any, stack: string) => {
    setCurrentEnvironment(environment);
    setCurrentStack(stack);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmDeleteStack = async () => {
    await deleteStack(currentEnvironment, currentStack);
    closeModal();
  };

  const openEnvModal = (environment: any) => {
    setCurrentEnvironment(environment);
    setIsEnvModalOpen(true);
  };

  const closeEnvModal = () => {
    setIsEnvModalOpen(false);
  };

  const confirmDeleteEnvironment = async () => {
    await deleteEnvironment(currentEnvironment);
    closeEnvModal();
  };

  return (
    <>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmDeleteStack}
        title="Confirm Delete Stack"
        message={`Are you sure you want to delete ${currentStack} for ${currentEnvironment?.name}?`}
      />
      <ConfirmModal
        isOpen={isEnvModalOpen}
        onClose={closeEnvModal}
        onConfirm={confirmDeleteEnvironment}
        title="Confirm Delete Environment"
        message={`Are you sure you want to delete ${currentEnvironment?.name}?`}
      />
      <div className="col-span-5">
        <dl className="grid grid-cols-1 gap-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
          {environments &&
            environments.map((environment: any) => (
              <div
                key={environment?.SK}
                className="relative dark:bg-gray-800 px-4 pt-6 pb-2 border-gray-400 rounded-lg overflow-hidden hover:border-cyan-300 border-2 border-solid"
              >
                {loading || !environment ? (
                  <div className="items-center animate-pulse">
                    <img className="h-14 mx-auto w-auto" src={logo} alt="Logo" />
                    <h2 className="mt-0 text-center text-xl font-bold text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>
                      Retrohook
                    </h2>
                  </div>
                ) : (
                  <>
                    <div className="flex">
                      <InputBox
                        name="Name"
                        onChange={(event: any) => handleEnvironmentNameChange(event, environment)}
                        value={environment?.name}
                        placeholder={environment?.name}
                      />
                      <button type="button" className="px-2 rounded-md ml-1" onClick={() => saveEnvironmentName(environment)}>
                        <CheckIcon className="h-5 w-7 text-gray-500 hover:text-cyan-400" />
                      </button>

                      <div className="flex justify-end space-x-2">
                        {!environment?.storage?.status?.includes('DELETE') || !environment?.storage ? null : (
                          <button
                            type="button"
                            className="text-xs text-gray-600  hover:bg-pink-500 hover:text-white dark:text-white font-bold px-2 py-1 focus:outline-none focus:shadow-outline"
                            onClick={() => openEnvModal(environment)}
                          >
                            <TrashIcon className="h-6 w-6" />
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="pt-2 place-items-center">
                      <p className="px-2 text-center text-xs text-gray-400">
                        {`${environment.accountId}`} | {`${environment.region}`}
                      </p>
                    </div>
                    <div className="grid grid-cols-3 gap-2 pt-4">
                      {stacks.map((stack) => {
                        const isStorage = stack === 'storage';
                        const status = environment[stack]?.status || '';
                        const storageStatus = environment.storage?.status;
                        const canDelete = status && !status?.includes('IN_PROGRESS') && !status?.includes('DELETE_COMPLETE');
                        const canDeploy = !environment[stack] || (environment[stack] && !status?.includes('PROGRESS') && !status?.includes('CREATE_COMPLETE'));
                        const storageCreateComplete = storageStatus?.includes('CREATE_COMPLETE');
                        const isDeployed = environment[stack]?.stackId && !status?.includes('PROGRESS') && !status?.includes('DELETE_COMPLETE');
                        const isRolledBack = status?.includes('ROLLBACK_COMPLETE') || status?.includes('ROLLBACK_FAILED');

                        const showRocket = (isStorage && canDeploy) || (!isStorage && canDeploy && storageCreateComplete);

                        return (
                          <div key={stack} className="text-center">
                            <p className="text-lg text-gray-400 capitalize">{stack}</p>
                            {status ? (
                              <p className="text-xs text-cyan-400 overflow-hidden">{stackStatusMap[status]}</p>
                            ) : (
                              <p className="text-xs text-cyan-400">Not Found</p>
                            )}

                            <div className="flex mt-1 items-center text-md text-white font-bold focus:outline-none focus:shadow-outline">
                              <div className="w-full items-center mx-2 space-x-2">
                                {canDelete && (
                                  <button
                                    type="button"
                                    className="px-2 py-1 bg-transparent dark:bg-gray-700 hover:bg-pink-500 rounded"
                                    onClick={() => openModal(environment, stack)}
                                  >
                                    <TrashIcon className="h-5 w-5 text-pink-400" />
                                  </button>
                                )}
                                {showRocket && (
                                  <button
                                    type="button"
                                    className="px-2 py-1 bg-transparent dark:bg-gray-700 hover:bg-cyan-500 rounded"
                                    onClick={() => deployStack(environment, stack)}
                                  >
                                    <RocketLaunchIcon className="h-5 w-5 text-cyan-400" />
                                  </button>
                                )}

                                {isDeployed && !canDelete && !isRolledBack && (
                                  <button
                                    type="button"
                                    onClick={() => notify({ message: 'Do Nothing for Now' })}
                                    className="px-2 py-1 bg-transparent dark:bg-gray-700 hover:bg-pink-500 rounded"
                                  >
                                    <ServerStackIcon className="h-5 w-5 text-pink-400" />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            ))}
        </dl>
        {environments.length === 0 && (
          <div className="flex items-center justify-center h-96">
            <div className="text-center">
              <p className="text-gray-400">No environments found</p>
              <p className="text-gray-400">Create an environment to get started</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
